<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('discounts')"
                        :isColumns="true"
                        :isNewButton="checkPermission('productdiscount_store')"
		                @new-button-click="add"
		                @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('discounts')"
                              :isColumns="true"
                              :isNewButton="checkPermission('productdiscount_store')"
		                      @new-button-click="add"
		                      @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
		        </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('period_code')">
                            <payment-periods-selectbox v-model="datatable.queryParams.filter.period_id">
                            </payment-periods-selectbox>
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? 'add' : 'edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="product_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('product')">
                                                <payment-products-selectbox v-model="form.product_id">
                                                </payment-products-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="period_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('period')">
                                                <payment-periods-selectbox v-model="form.period_id">
                                                </payment-periods-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="customer_group" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('customer_group')">
                                                <b-form-select v-model="form.customer_group"
                                                               :options="options_customer_group"></b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="early_discount_rate" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('early_discount_rate')">
                                                <b-form-input type="number" v-model="form.early_discount_rate">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="cash_discount_rate" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('cash_discount_rate')">
                                                <b-form-input type="number" v-model="form.cash_discount_rate">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="start_date" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('start_date')">
                                                <b-form-input type="datetime-local" v-model="form.start_date">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('end_date')">
                                                <b-form-input type="datetime-local" v-model="form.end_date">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="registration_academic_years" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('registration_academic_years')">
                                                <academic-years-selectbox v-model="form.registration_academic_years"
                                                                          :validate-error="errors[0]"
                                                                   :multiple="true"></academic-years-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="excluded_registration_academic_years" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('excluded_registration_academic_years')">
                                                <academic-years-selectbox v-model="form.excluded_registration_academic_years"
                                                                          :validate-error="errors[0]"
                                                                          :multiple="true"></academic-years-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider name="program_levels" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('program_level')">
                                                <parameter-selectbox code="program_levels" v-model="form.program_levels"
                                                                     :multiple="true"></parameter-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="excluded_program_codes" rules=""
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('excluded_program_codes')">
                                                <program-selectbox v-model="form.excluded_program_codes"
                                                                   :multiple="true"></program-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t('save') | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from 'qs'
import ProductDiscountService from "@/services/ProductDiscountService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ProgramSelectbox from '../../../components/interactive-fields/ProgramSelectbox.vue';
import ParameterSelectbox from '../../../components/interactive-fields/ParameterSelectbox.vue';
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import PaymentProductsSelectbox from "../../../components/interactive-fields/PaymentProductsSelectbox";


export default {
    components: {
        PaymentProductsSelectbox,
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        ProgramSelectbox,
        ParameterSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('İndirimler')
        }
    },
    data() {
        return {
            id: 0,
            form: {},
            options_customer_group: [
                { value: 'TR', text: this.$t('TR') },
                { value: 'YU', text: this.$t('YU') },
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 w-100-below-lg align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission:'productdiscount_update',
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                }
                            },
                            {
                                text: this.$t('delete'),
                                permission:'productdiscount_delete',
                                class: 'ri-delete-bin-2-line',
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('period')),
                        field: 'period_id',
                        sortable: true,
                        hidden:false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('period_code')),
                        field: this.getLocaleField('period_code'),
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.$t('product'),
                        field: this.getLocaleField('product_name'),
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.$t('start_date'),
                        field: this.getLocaleField('start_date'),
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.$t('end_date'),
                        field: this.getLocaleField('end_date'),
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('customer_group')),
                        field: this.getLocaleField('customer_group'),
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('cash_discount_rate')),
                        field: 'cash_discount_rate',
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('early_discount_rate')),
                        field: 'early_discount_rate',
                        sortable: false,
                        hidden:false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return ProductDiscountService.getAll(config)
                                         .then((response) => {
                                             this.datatable.rows = response.data.data
                                             this.datatable.total = response.data.pagination.total
                                         })
                                         .finally(() => {
                                             this.datatable.isLoading = false;
                                         });
        },
        loadData() {
            if (this.id > 0) {
                ProductDiscountService.get(this.id)
                                      .then(response => {
                                          this.$refs.storeForm.reset();
                                          this.form = response.data.data;
                                          this.$refs.modal.$refs.commonModal.show();
                                      })
                                      .catch(error => {
                                          if (error.data.message) {
                                              this.$toast.error(this.$t('api.' + error.data.message));
                                          }
                                      });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                if (this.id == 0) {

                    ProductDiscountService.store(this.form, this.value)
                                          .then((response) => {
                                              this.$toast.success(this.$t("api." + response.data.message));
                                          })
                                          .catch((error) => {
                                              this.showErrors(error, this.$refs.storeForm)
                                          });
                }
                else {
                    ProductDiscountService.update(this.id, this.form)
                                          .then((response) => {
                                              this.$toast.success(this.$t("api." + response.data.message));
                                          })
                                          .catch((error) => {
                                              this.showErrors(error, this.$refs.storeForm)
                                          })
                }

            }
        },
        delete(id) {
            this.deleteModal(() => {
                ProductDiscountService.deleteProductDiscount(id)
                                      .then(response => {
                                          this.filter();
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(error => {
                                          this.$toast.error(this.$t('api.' + error.data.message));
                                      });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        }
    }
};
</script>

